var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-row",
        [
          _c("span", [_vm._v("搜索：")]),
          _c(
            "el-select",
            {
              staticClass: "select-all",
              attrs: { placeholder: "请选择" },
              model: {
                value: _vm.searchQuery.obj_type,
                callback: function($$v) {
                  _vm.$set(_vm.searchQuery, "obj_type", $$v)
                },
                expression: "searchQuery.obj_type"
              }
            },
            _vm._l(_vm.searchOption, function(item) {
              return _c("el-option", {
                key: item.value,
                attrs: { label: item.label, value: item.value }
              })
            }),
            1
          ),
          _c(
            "el-select",
            {
              staticClass: "select-remote",
              attrs: {
                disabled: _vm.searchQuery.obj_type == 0,
                filterable: "",
                remote: "",
                "reserve-keyword": "",
                "value-key": "id",
                placeholder: _vm.searchPlace[_vm.searchQuery.obj_type],
                "remote-method": _vm.remoteVillage,
                clearable: "",
                loading: _vm.villageLoading
              },
              on: { change: _vm.handleSelect },
              model: {
                value: _vm.searchQuery.obj_id,
                callback: function($$v) {
                  _vm.$set(_vm.searchQuery, "obj_id", $$v)
                },
                expression: "searchQuery.obj_id"
              }
            },
            _vm._l(_vm.villageOptions, function(item) {
              return _c(
                "el-option",
                { key: item.id, attrs: { label: item.name, value: item.id } },
                [
                  _c("span", { staticStyle: { float: "left" } }, [
                    _vm._v(_vm._s(item.name))
                  ]),
                  _c(
                    "span",
                    {
                      staticStyle: {
                        float: "right",
                        color: "#8492a6",
                        "font-size": "13px"
                      }
                    },
                    [_vm._v(_vm._s(item.contact || item.region.name))]
                  )
                ]
              )
            }),
            1
          ),
          _c(
            "el-button",
            {
              staticClass: "m-l-10",
              attrs: { type: "primary" },
              on: { click: _vm.handleSearch }
            },
            [_vm._v("查询")]
          )
        ],
        1
      ),
      _c(
        "el-row",
        {
          staticClass: "filter-item",
          staticStyle: { "margin-top": "10px" },
          attrs: { type: "flex", justify: "space-between" }
        },
        [
          _c("el-col", { staticClass: "filter-left" }, [
            _vm._v(_vm._s(_vm.filterName || "全部"))
          ]),
          _c(
            "el-col",
            { staticClass: "text-right" },
            [
              _c(
                "el-radio-group",
                {
                  on: {
                    change: function($event) {
                      return _vm.handleChange(1)
                    }
                  },
                  model: {
                    value: _vm.searchQuery.type,
                    callback: function($$v) {
                      _vm.$set(_vm.searchQuery, "type", $$v)
                    },
                    expression: "searchQuery.type"
                  }
                },
                [
                  _c("el-radio-button", { attrs: { label: "1" } }, [
                    _vm._v("一周")
                  ]),
                  _c("el-radio-button", { attrs: { label: "2" } }, [
                    _vm._v("一月")
                  ]),
                  _c("el-radio-button", { attrs: { label: "3" } }, [
                    _vm._v("一年")
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-row",
        [
          _c("ve-histogram", {
            attrs: {
              data: _vm.chartData,
              "data-empty": _vm.dataEmpty,
              loading: _vm.chartLoading,
              toolbox: _vm.toolbox,
              extend: _vm.settings
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { position: "relative" } },
        [
          _c(
            "el-tabs",
            {
              on: { "tab-click": _vm.handleClick },
              model: {
                value: _vm.agentForm.level,
                callback: function($$v) {
                  _vm.$set(_vm.agentForm, "level", $$v)
                },
                expression: "agentForm.level"
              }
            },
            [
              _c("el-tab-pane", { attrs: { label: "一级代理", name: "zero" } }),
              _c("el-tab-pane", { attrs: { label: "二级代理", name: "1" } }),
              _c("el-tab-pane", { attrs: { label: "三级代理", name: "2" } })
            ],
            1
          ),
          _c(
            "el-radio-group",
            {
              staticClass: "radio-group_time",
              on: {
                change: function($event) {
                  return _vm.handleChange(2)
                }
              },
              model: {
                value: _vm.agentForm.type,
                callback: function($$v) {
                  _vm.$set(_vm.agentForm, "type", $$v)
                },
                expression: "agentForm.type"
              }
            },
            [
              _c("el-radio-button", { attrs: { label: "1" } }, [
                _vm._v("一周")
              ]),
              _c("el-radio-button", { attrs: { label: "2" } }, [
                _vm._v("一月")
              ]),
              _c("el-radio-button", { attrs: { label: "3" } }, [_vm._v("一年")])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-row",
        {
          staticClass: "table-container",
          staticStyle: { "margin-top": "10px" }
        },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.tableLoading,
                  expression: "tableLoading"
                }
              ],
              staticStyle: { height: "330px", "overflow-y": "auto" },
              attrs: {
                data: _vm.dataList,
                "default-sort": { prop: "money", order: "descending" },
                border: ""
              }
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "name",
                  label: "代理商名称",
                  "header-align": "center",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "area",
                  label: "区域",
                  "header-align": "center",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "money",
                  sortable: "",
                  label: "营业额",
                  "header-align": "center",
                  align: "center"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-row",
        [
          _c(
            "el-row",
            { staticStyle: { "margin-top": "22px" } },
            [
              _c("ve-line", {
                attrs: { data: _vm.lineDatas, toolbox: _vm.toolbox }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }