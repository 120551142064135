import "core-js/modules/es6.function.name";
import "core-js/modules/es6.array.find";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { fetchAgentList, fetchUserHabit, fetchConsu, fetchAgentTurnover, fetchPropertyList } from "@/api/charge";
import { transSelectState } from "@/utils/util";
import "@/utils/global.js";
import VeLine from "v-charts/lib/line.common";
import VeHistogram from "v-charts/lib/histogram.common";
import "echarts/lib/component/toolbox";
import Pagination from "@/components/Pagination";
import "v-charts/lib/style.css";
import { subDays, format, getDay, getMonth } from "date-fns";
export default {
  name: "equipmentList",
  components: {
    VeLine: VeLine,
    VeHistogram: VeHistogram,
    Pagination: Pagination
  },
  data: function data() {
    this.settings = {
      series: {
        barMaxWidth: 40,
        barGap: "0"
      }
    };
    return {
      searchQuery: {
        type: 1,
        obj_id: "",
        obj_type: 0
      },
      searchOption: _global.searchOption,
      searchPlace: ["", "代理商关键字", "小区关键字"],
      chartData: {
        columns: ["日期", "成交量", "订单金额", "退款金额"],
        rows: []
      },
      villageLoading: false,
      villageOptions: [],
      agentForm: {
        level: "zero",
        type: 1
      },
      // 表格数据
      dataList: [],
      tableLoading: false,
      lineDatas: {
        columns: ["时间", "次数"],
        rows: []
      },
      toolbox: {
        //可视化的工具箱
        show: true,
        feature: {
          dataView: {
            //数据视图
            show: true
          },
          saveAsImage: {
            //保存图片
            show: true
          }
        }
      },
      filterName: "",
      dataEmpty: false,
      chartLoading: true
    };
  },
  created: function created() {
    this.getHabit();
    this.getTurnover();
    this.getConsu(); // this.get7Days()
  },
  methods: {
    getHabit: function getHabit() {
      var _this = this;

      fetchUserHabit().then(function (res) {
        console.log(res.data);

        _this.transTrade(res.data);
      });
    },
    getTurnover: function getTurnover() {
      var _this2 = this;

      // 排名
      var data = Object.assign({}, this.agentForm);

      if (this.agentForm.level == "zero") {
        data["level"] = 0;
      }

      fetchAgentTurnover(data).then(function (res) {
        _this2.dataList = res.data;
      });
    },
    getConsu: function getConsu() {
      var _this3 = this;

      // 排名
      this.chartLoading = false;
      this.chartData.rows = [];
      fetchConsu(this.searchQuery).then(function (res) {
        _this3.dataEmpty = true;

        if (res.data.length > 0) {
          _this3.dataEmpty = false;

          _this3.transConsu(res.data);
        }
      }).catch(function (err) {
        _this3.dataEmpty = true;
        console.log(err);
      });
    },
    transTrade: function transTrade(data) {
      var arr = [];
      var txt = this.lineDatas.columns;

      for (var i = 0; i < data.length; i++) {
        var obj = {};
        obj[txt[0]] = data[i]["time"];
        obj[txt[1]] = data[i]["use_count"];
        arr.push(obj);
      }

      this.lineDatas.rows = arr;
    },
    transConsu: function transConsu(data) {
      var arr = [];
      var txt = this.chartData.columns;

      for (var i = 0; i < data.length; i++) {
        var obj = {};
        obj[txt[0]] = data[i]["time"];
        obj[txt[1]] = data[i]["num"];
        obj[txt[2]] = data[i]["money"];
        obj[txt[3]] = data[i]["total_refund"];
        arr.push(obj);
      }

      this.chartData.rows = arr;
    },
    remoteVillage: function remoteVillage(query) {
      var _this4 = this;

      console.log("village remote");

      if (query !== "") {
        this.villageLoading = true;
        setTimeout(function () {
          _this4.villageLoading = false;
          var data = {
            keyword: query
          };

          if (_this4.searchQuery.obj_type == 1) {
            fetchAgentList(data).then(function (res) {
              console.log(res);
              _this4.villageOptions = res.data;
            });
          } else {
            fetchPropertyList(data).then(function (res) {
              console.log(res);
              _this4.villageOptions = res.data;
            });
          }
        }, 100);
      } else {
        this.villageOptions = [];
      }
    },
    handleChange: function handleChange(type) {
      console.log(type);

      if (type == 1) {
        this.getConsu();
      } else {
        // this.agentForm.type = type
        this.getTurnover();
      }
    },
    handleClick: function handleClick(tab, event) {
      // console.log(tab.name, event);
      this.getTurnover();
    },
    handleSearch: function handleSearch() {
      this.getConsu();
    },
    handleSelect: function handleSelect(val) {
      console.log("hahah", val);
      var obj = {};
      obj = this.villageOptions.find(function (item) {
        return item.id === val;
      });
      console.log(obj);
      this.filterName = this.transSelectState(this.searchQuery.obj_type) + ":" + obj["name"];
      this.getConsu();
    },
    get7Days: function get7Days() {
      console.log(subDays(new Date(), 1));
    },
    transSelectState: transSelectState
  }
};